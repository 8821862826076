import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModTwoImgCols from "../../../components/Chronology/Modules/ModTwoImgCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModReport from "../../../components/Chronology/Modules/ModReport";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import { StrongWhite, StrongGold } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModVideo from "../../../components/Chronology/Modules/ModVideo";
import ModFootnote from "../../../components/Chronology/Modules/ModFootnote";
import ModDataEs from "../../../components/Chronology/Modules/ModDataEs";

export const frontmatter = {
  title: "Día 110",
  week: "Semana 16",
  day: "01",
  month: "Jul",
  monthNumber: "07",
  date: "2020-07-01",
  path: "/cronologia/semana-16#dia-01-jul/",
};

const Day110 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.902 casos mediante pruebas PCR. En ese mismo
          periodo, 151 personas han requerido hospitalización (7,9% de los
          diagnósticos), de los que nueve han sido ingresos en la UCI, y se han
          producido 19 fallecimientos.
        </ModText>
        <ModImage
          src="/images/svg/11_abr_aduana.svg"
          alt="fronteras abiertas"
        />

        <ModText>
          A partir de hoy,{" "}
          <strong>
            la Unión Europea comienza la reapertura de sus fronteras exteriores
          </strong>{" "}
          y autoriza la entrada a los ciudadanos y residentes de una selección
          de 15 países, entre los que no se encuentran EE UU, Brasil, Rusia o
          México.
        </ModText>
        <ModText>
          La selección se ha realizado de acuerdo con varios criterios, entre
          los que destacan:
        </ModText>
        <ModText>
          <strong>1. Criterio epidemiológico:</strong> entre los países que
          presentan una ratio similar o inferior a la media europea en cuanto a
          la incidencia de personas por cada 100.000 habitantes. En concreto, en
          aquellos con incidencia de 16 personas por 100.000 habitantes.
        </ModText>
        <ModText>
          <strong>2. Criterio de reciprocidad:</strong> que los países terceros
          admitan la entrada de ciudadanos europeos.
        </ModText>
        <ModText>
          El listado incluye a Argelia, Australia, Canadá, Corea del Sur, Japón,
          Georgia, Marruecos, Montenegro, Nueva Zelanda, Ruanda, Serbia,
          Tailandia, Túnez y Uruguay. La lista también incluye a China, pero
          supeditado a que el Gobierno de Pekín autorice la entrada de
          ciudadanos europeos, dado que la reciprocidad es una de las
          condiciones exigidas por Bruselas para la apertura.
        </ModText>
        <ModImage
          src="/images/svg/21_mar_comite_cientifico.svg"
          alt="estudio cientifico VIH vs Covid-19"
        />

        <ModText>
          El Ministerio de Sanidad, por su parte, ha realizado un estudio sobre
          la <strong>incidencia de COVID-19 en personas con VIH</strong> que
          reciben tratamiento antirretroviral, y los resultados sugieren que el
          riesgo no es mayor en las personas VIH positivas que en la población
          general.
        </ModText>
        <ModText>
          Además, muestra que las personas VIH positivas en tratamiento con
          TDF/FTC tienen un menor riesgo de infección y hospitalización por
          COVID-19 que otras personas VIH positivas con otras pautas de
          tratamiento.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day110;
